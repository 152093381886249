const website = require('./website')

module.exports = {
  'sr-rs': {
    default: true,
    path: 'sr',
    locale: 'sr-rs',
    siteLanguage: 'sr',
    ogLang: 'sr_Latn',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: 'Bazirano na gatsby-starter-prismic sa podrškom za lokalizaciju (i18n)',
    headline: 'Pisanje i objavljivanje za Krafter',
    category: 'Kategorija',
    categories: 'Kategorije',
    was: 'bio',
    were: 'su',
    tagged: 'markirano sa',
    recent: 'Novo',
    projects: 'Projekti',
    allCategories: 'Sve kategorije',
    entries: 'Objave',
  },
  'de-de': {
    path: 'de',
    locale: 'de-de',
    siteLanguage: 'de',
    ogLang: 'de_DE',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: 'Basierend auf gatsby-starter-prismic mit Unterstützung für Lokalisierung (i18n)',
    headline: 'Schreiben und Veröffentlichen für Krafter',
    category: 'Kategorie',
    categories: 'Kategorien',
    was: 'wurde',
    were: 'wurden',
    tagged: 'markiert mit',
    recent: 'Neue',
    projects: 'Projekte',
    allCategories: 'Alle Kategorien',
    entries: 'Einträge',
  },
  'en-gb': {
    path: 'en',
    locale: 'en-gb',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    projects: 'projects',
    allCategories: 'All categories',
    entries: 'entries',
  },
}
